.group {
  display: block;
  padding: 0 var(--space-s);

  page-break-inside: avoid;
  break-inside: avoid;
}

.groupName {
  font-weight: 500;
  font-size: var(--size-n);
  line-height: 1.5;
}

.category {
  display: block;
  margin-bottom: var(--space-xs);

  color: var(--color-black);

  font-size: var(--size-s);
  line-height: 1.5;

  transition: all 0.2s;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: var(--color-dark-gray);
  }

  &:active {
    color: var(--color-black);
    font-weight: 500;
  }
}

.categories {
  margin-top: var(--space-xs);
}
