.content {
  max-height: 100vh;

  background: var(--color-white);

  &.needScroll {
    height: 100%;
  }
}

.contentInner {
  box-sizing: border-box;
  height: 100%;
  padding: var(--space-m) 0 var(--space-l);
}

.containerLabel {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-s);

  cursor: pointer;
}

.label {
  font-size: var(--size-n);
  line-height: 1.5;
}

.scroller {
  height: 100%;
}

.groups {
  box-sizing: border-box;
  height: 100%;
  padding: var(--space-l) 0 var(--space-s);
}

.group {
  margin-bottom: var(--space-l);
  padding-right: var(--space-xs);

  page-break-inside: avoid;
  break-inside: avoid;
}

.column1 {
  column-count: 1;
}
.column2 {
  column-count: 2;
}
.column3 {
  column-count: 3;
}
.column4 {
  column-count: 4;
}

@media (--mobile) {
  .group {
    margin-bottom: var(--space-n);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
