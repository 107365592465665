.enterActive {
  &.container {
    opacity: 1;
  }
}

.enterDone {
  &.container {
    opacity: 1;
  }
}

.enter {
  &.slideBottom {
    transform: translate3d(0, 100%, 0);
  }
}

.enterActive {
  &.slideBottom {
    .wrapper {
      transform: translate3d(0, 100%, 0);
    }
  }
}

.enterDone {
  &.slideBottom {
    .wrapper {
      transform: translate3d(0, 0, 0);
    }
  }
}

.exit {
  &.slideBottom {
    .wrapper {
      transform: translate3d(0, 0, 0);
    }
  }
}

.exitActive {
  &.slideBottom {
    .wrapper {
      transform: translate3d(0, 100%, 0);
    }
  }
}

.slideBottom {
  .wrapper {
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;

    transition: height 0.2s linear, transform 0.4s ease-in-out;
  }
}

.slideBottom {
  .content {
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);

  width: 100%;

  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;

  transition: opacity 0.4s ease-in-out;

  &.fullscreen {
    .wrapper {
      display: grid;
      grid-template: 1fr / 1fr;
      padding: 0;
      overflow: auto;
    }

    .content {
      width: 100%;
      height: inherit;
      max-height: 100%;
    }
  }

  &.hidden {
    display: none;
  }

  &.stretch {
    .content {
      height: 100%;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.scroller {
  height: 100%;
}

.content {
  position: relative;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.arrowBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  min-height: 34px;
  margin: 0 var(--space-l);

  background-color: var(--color-white);
  border-radius: 100%;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-light-gray);
  }
}

.arrow {
  width: 11px;
  height: 16px;

  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.6;

  transition: opacity 0.2s;
}

.arrowBackground.prev {
  .arrow {
    background-image: url('./icon-prev.svg');
  }
}

.arrowBackground.next {
  .arrow {
    background-image: url('./icon-next.svg');
  }
}

@media (--mobile-m) {
  .container {
    &.fullscreen {
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    &.stretch {
      .content {
        display: block;
      }
    }
  }
}

@media (--mobile) {
  .container {
    opacity: 1;
  }

  .enter {
    transform: translate3d(0, 100%, 0);
  }

  .enterActive {
    .wrapper {
      transform: translate3d(0, 100%, 0);
    }
  }

  .enterDone {
    .wrapper {
      transform: translate3d(0, 0, 0);
    }
  }

  .exit {
    .wrapper {
      transform: translate3d(0, 0, 0);
    }
  }

  .exitActive {
    .wrapper {
      transform: translate3d(0, 100%, 0);
    }
  }

  .wrapper {
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;

    transition: height 0.2s linear, transform 0.4s ease-in-out;
  }

  .content {
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }
}
